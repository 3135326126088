.form {
  width: 100%;
  max-width: 416px;
  padding: 24px 32px 32px;
  background: $mainTxtLight;
  border-radius: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  &--goal {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 784px;
  }
  &__info {
    width: 100%;
    max-width: 352px;
  }
  &__action {
    &--goal {
      max-width: 304px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: rem(24);
    line-height: 133%;
    text-align: center;
    color: $mainTxtDark;
  }
  &__error {
    color: red;
    font-size: rem(10);
    margin-bottom: 5px;
  }
  &__discount {
    background-color: $bgDiscount;
    border-radius: 16px;
    padding: 16px 16px 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--goal {
      background-color: $input;
    }
  }
  &__discount-text {
    font-weight: normal;
    font-size: rem(16);
    line-height: 120%;
    color: $mainTxtLight;
    max-width: 150px;
  }
  &__text {
    font-weight: normal;
    font-size: rem(16);
    line-height: 120%;
    text-align: center;
    color: $mainTxtDark;
    margin: 24px 0;
    &--goal {
      margin-top: 6px;
    }
  }
  &__input {
    background: $btn;
    border-radius: 16px;
    padding: 16px 0;
    display: block;
    width: 100%;
    border: none;
    text-indent: 32px;
    margin-bottom: 8px;
    font-weight: normal;
    font-size: rem(16);
    line-height: 157%;
    color: $mainTxtDark;
    &::placeholder {
      color: $input;
      font-size: rem(16);
    }
  }
  &__btn {
    border: none;
    &:disabled {
      background-color: grey;
      cursor: not-allowed;
    }
  }
  &__head {
    position: absolute;
    width: 100%;
    max-width: 200px;
    top: -125px;
    right: -45px;
  }
}
@media (max-width: 980px) {
  .form {
    &__head {
      display: none;
    }
  }
}
@media (max-width: 740px) {
  .form {
    padding: 24px 16px;
    border-radius: 16px;
    &--goal {
      flex-direction: column;
      max-width: 480px;
      align-items: center;
      margin: 0 auto;
    }
    &__title {
      font-size: rem(16);
    }
    &__discount {
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      flex-direction: column;
      &--goal {
        background-color: transparent;
      }
    }
    &__discount-text {
      font-size: rem(12);
      color: $mainTxtDark;
      text-align: center;
      max-width: 113px;
      margin-bottom: 8px;
    }
    &__text {
      margin: 16px 0 24px;
      &--goal {
        margin-top: 6px;
      }
    }
    &__btn {
      margin-top: 16px;
    }
  }
}