.btn {
  font-weight: 600;
  font-size: rem(20);
  line-height: 126%;
  text-align: center;
  text-transform: uppercase;
  color: $btn;
  background: $gradient;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  padding: 21px 0;
  text-decoration: none;
  display: block;
  width: 100%;
  cursor: pointer;
  transition: all 1s ease-in-out;
  &:hover {
    background: $hover;
    transition: all 1s ease-in-out;
  }
}

@media (max-width: 740px) {
  .btn {
    padding: 16px 0;
  }
}