.price {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 33px 0 13px;
  gap: 20px;
  &__new {
    font-weight: bold;
    font-size: rem(40);
    line-height: 100%;
    color: $mainTxtDark;
  }
  &__text {
    font-weight: normal;
    font-size: rem(16);
    line-height: 150%;
    color: $mainTxtDark;
  }
  &__old {
    font-weight: normal;
    font-size: rem(16);
    line-height: 150%;
    color: $mainTxtDark;
  }
}
@media (max-width: 740px) {
  .price {
    margin: 32px 0 22px;
    &__new {
      font-size: rem(35);
    }
    &__text {
      font-size: rem(14);
    }
    &__old {
      font-size: rem(14);
    }
  }
}