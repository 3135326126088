$mainTxtDark: #000000;
$mainTxtLight: #FFFFFF;
$btn: #E5E5E5;
$bgStory: #B800BA;
$bgInd: #6500E5;
$input: #676767;
$bgDiscount: #B800BA;
$reviews: #C4C4C4;
$hover: #460080;
$gradient: linear-gradient(90deg, #CC00A9 0%, #4F0090 100%);
/* Стандартный transition для hover-анимаций */

@mixin transition {
  transition: 0.3s cubic-bezier(0.250, 0.100, 0.250, 1.000);
}


/* Миксин замены цвета placeholder у input.
Нужно добавить в стилях элемента конструкцию вида @include placeholder(#000); */
@mixin placeholder($color, $opacity: 1) {
  &::-webkit-input-placeholder {color:$color; opacity: $opacity}
  &::-moz-placeholder          {color:$color; opacity: $opacity}
  &:-ms-input-placeholder      {color:$color; opacity: $opacity}
}

//flex-миксин
@mixin flex-dir($dir: column, $jc:center, $ai:center) {
  display: flex;
  flex-direction: $dir;
  justify-content: $jc;
  align-items: $ai;
}

//Перевод пикселей в емы
@function rem($pixels, $context: 16) {
  @return $pixels / $context * 1rem;
}




body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: $mainTxtDark;
}

* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,p{
  font-weight: normal;
  margin: 0;
  padding: 0;
}
ul,li {
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1345px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
}


@import './src/blocks/banner/banner';
@import './src/blocks/btn/btn';
@import './src/blocks/story/story';
@import './src/blocks/about/about';
@import './src/blocks/programm/programm';
@import './src/blocks/independence/independence';
@import './src/blocks/form/form';
@import './src/blocks/timer/timer';
@import './src/blocks/price/price';
@import './src/blocks/skills/skills';
@import './src/blocks/reviews/reviews';
@import './src/blocks/goal/goal';
@import './src/blocks/questions/questions';
@import './src/blocks/footer/footer';