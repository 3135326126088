.reviews {
  background-image: url("../img/bgAbout.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 144px 0 280px;
  &__title {
    font-weight: bold;
    font-size: rem(64);
    line-height: 112%;
    text-align: center;
    color: $mainTxtDark;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: '';
      background-image: url("../img/head10.png");
      background-repeat: no-repeat;
      width: 216px;
      height: 242px;
      display: inline-block;
      transform: translate(-10px,-35px);
    }
  }
  &__item-holder {
    height: auto;
    position: relative;
    display: flex !important;
    flex-direction: column;
    &:after {
      content: '';
      display: block;
      height: 46px;
      width: 43px;
      background: $mainTxtLight;
      border: inherit;
      align-self: flex-end;
      transform: translate(-84px,-22px) rotate(-45deg);
    }
  }
  &__item {
    background: $mainTxtLight;
    border-radius: 24px;
    padding: 32px 32px 24px;
    width: 100%;
    max-width: 464px;
    height: 100%;
    margin-right: 32px;
  }
  &__item-images {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
  }
  &__item-photo {
    width: 100%;
    max-width: 72px;
  }
  &__item-stars {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 152px;
  }
  &__item-star {
    width: 100%;
    max-width: 24px;
  }
  &__item-name {
    font-weight: 600;
    font-size: rem(24);
    line-height: 133%;
    color: $mainTxtDark;
    margin: 16px 0 24px;
  }
  &__item-text {
    font-weight: normal;
    font-size: rem(16);
    line-height: 150%;
    color: $mainTxtDark;
    &--bold {
      font-weight: 700;
    }
  }
  &__icon {
    display: block;
    margin: 0 auto;
    animation: slide 4s linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
}

.slick-track {
  display: flex;
  align-items: stretch;
}
@keyframes slide {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
    transform: translate(-20px, 10px);
  }
  20% {
    opacity: 1;
    transform: translate(-40px, 10px);
  }
  30%, 100% {
    opacity: 0;
  }
}

@media (max-width: 740px)  {
  .reviews {
    overflow: hidden;
    padding: 56px 16px 40px;
    &__title {
      font-size: rem(24);
      margin-bottom: 24px;
      position: relative;
      &:after {
        position: absolute;
        right: -16px;
        background-size: contain;
        width: 99px;
        height: 99px;
        margin-bottom: -20px;
        filter: drop-shadow(0px 4px 35px rgba(197, 0, 229, 0.25));
      }
    }
    &__slider {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;

    }
    &__item-holder {
      //width: 100%;
      //max-width: 700px;
      &:after {
        content: '';
        //display: block;
        height: 46px;
        width: 43px;
        background: $mainTxtLight;
        border: inherit;
        align-self: flex-end;
        transform: translate(-520%,-22px) rotate(-45deg);
        display: none;
      }
    }
    &__item {
      border-radius: 16px;
      padding: 16px 8px 40px 16px;
      width: 100%;
      max-width: 312px;
      margin: 0 auto;
    }
  }
}