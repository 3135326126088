.questions {
  padding: 280px 0 225px;
  overflow: hidden;
  &__title {
    font-weight: bold;
    font-size: rem(64);
    line-height: 112%;
    text-align: center;
    color: $mainTxtDark;
    margin-bottom: 61px;
  }
  &__item {
    background: $mainTxtLight;
    box-shadow: 0 4px 63px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    padding: 24px 40px;
    margin: 0 auto 20px;
    position: relative;
    width: 100%;
    max-width: 1088px;
    &:before {
      content: '';
      width: 216px;
      height: 216px;
      display: inline-block;
      position: absolute;
      background-repeat: no-repeat;
      bottom: 0;
      left: -150px;
      background-size: contain;
    }
    &:after {
      content: '';
      width: 216px;
      height: 216px;
      display: inline-block;
      position: absolute;
      background-repeat: no-repeat;
      bottom: 0;
      right: -150px;
      background-size: contain;
    }
    &--1:before {
      background-image: url("../img/head12.png");
    }
    &--2:after {
      background-image: url("../img/head13.png");
    }
    &--3:before {
      background-image: url("../img/head14.png");
    }
    &--4:after {
      background-image: url("../img/head15.png");
    }
  }
  &__item-title {
    font-weight: 600;
    font-size: rem(32);
    line-height: 125%;
    color: $mainTxtDark;
    //max-width: 935px;
    position: relative;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    &:after {
      content: '';
      background-image: url("../img/arrow.png");
      display: inline-block;
      background-repeat: no-repeat;
      background-size: contain;
      width: 16px;
      height: 16px;
      transform: scaleY(-1);
      cursor: pointer;
      transition: all .4s linear;
      flex-shrink: 0;
    }
    &.active:after {
      transform: scaleY(1);
    }
  }
  &__item-text {
    font-weight: normal;
    font-size: rem(24);
    line-height: 133%;
    color: $mainTxtDark;
    margin-top: 16px;
    max-width: 1008px;
    display: none;
  }
}
@media (max-width: 1270px) {
  .questions {
    &__item {
      background: $mainTxtLight;
      box-shadow: 0 4px 63px rgba(0, 0, 0, 0.15);
      border-radius: 24px;
      padding: 24px 40px;
      margin: 0 auto 20px;
      position: relative;
      width: 100%;
      max-width: 1088px;
      &--1:before {
        left: auto;
        right: -40px;
        bottom: auto;
        top: -95px;
        width: 104px;
        height: 104px;
      }
      &--2:after {
        width: 48px;
        right: -10px;
        height: 48px;
        bottom: 0;
      }
      &--3:before {
        width: 60px;
        height: 60px;
        left: -30px;
        bottom: auto;
        top: -60px;
      }
      &--4:after {
        width: 56px;
        height: 56px;
        bottom: 0;
        right: -10px;
      }
    }
  }
}
@media (max-width: 740px) {
  .questions {
    padding: 40px 0 16px;
    &__title {
      font-size: rem(24);
      margin-bottom: 40px;
    }
    &__item {
      border-radius: 8px;
      padding: 16px;
      margin: 0 auto 16px;
    }
    &__item-title {
      font-size: rem(18);
    }
    &__item-text {
      font-size: rem(16);
    }
  }
}
