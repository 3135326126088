@charset "UTF-8";
/* Стандартный transition для hover-анимаций */
/* Миксин замены цвета placeholder у input.
Нужно добавить в стилях элемента конструкцию вида @include placeholder(#000); */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #000000; }

* {
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6, p {
  font-weight: normal;
  margin: 0;
  padding: 0; }

ul, li {
  padding: 0;
  margin: 0; }

.container {
  width: 100%;
  max-width: 1345px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative; }

.banner {
  position: relative;
  background-image: url("../img/bannerBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 192px 0 220px;
  overflow: hidden; }
  .banner__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 10; }
  .banner__img {
    position: absolute;
    width: 100%;
    max-width: 850px;
    bottom: -400px;
    right: -130px; }
  .banner__head {
    position: absolute;
    width: 100%; }
    .banner__head--1 {
      max-width: 300px;
      top: -125px;
      right: 20px; }
    .banner__head--2 {
      max-width: 300px;
      bottom: 80px;
      right: 32%; }
    .banner__head--3 {
      max-width: 216px;
      bottom: -170px;
      right: -25px; }
  .banner__notification {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 125%;
    color: #FFFFFF;
    background: linear-gradient(90deg, #CC00A9 0%, #4F0090 100%);
    border-radius: 144px;
    display: inline-block;
    padding: 8px 18px; }
  .banner__title {
    font-weight: bold;
    font-size: calc(26px + 32 * ((100vw - 320px)/ 1120));
    line-height: 122%;
    letter-spacing: 0.01em;
    color: #000000;
    width: 100%;
    max-width: 1256px;
    margin: 80px 0 56px; }
  .banner__text {
    font-weight: 500;
    font-size: calc(16px + 11 * ((100vw - 320px)/ 1120));
    line-height: 125%;
    color: #000000;
    width: 100%;
    max-width: 640px; }
  .banner__list {
    list-style: none;
    margin: 80px 0 29px; }
  .banner__item {
    font-weight: 500;
    font-size: calc(16px + 6 * ((100vw - 320px)/ 1120));
    line-height: 125%;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 24px; }
    .banner__item:before {
      content: '';
      background-image: url("../img/check.png");
      width: 24px;
      height: 24px;
      display: inline-block;
      flex-shrink: 0;
      margin-right: 29px; }
  .banner__btn {
    max-width: 352px; }

@media (max-width: 1115px) {
  .banner__img {
    max-width: calc(100vw - 55vw);
    bottom: -220px;
    right: 0;
    z-index: 2; }
  .banner__head--1 {
    max-width: 130px; }
  .banner__head--2 {
    max-width: 130px; }
  .banner__head--3 {
    max-width: 130px; } }

@media (max-width: 740px) {
  .banner {
    padding: 40px 0 48px; }
    .banner__head--1 {
      max-width: 70px;
      top: auto;
      bottom: 17px;
      right: 0; }
    .banner__head--2 {
      max-width: 130px;
      bottom: auto;
      top: 19%;
      right: auto;
      left: 0;
      transform: rotate(-15deg); }
    .banner__head--3 {
      max-width: 130px;
      bottom: 42%;
      right: auto;
      left: -20px;
      transform: rotate(10deg); }
    .banner__img {
      max-width: 290px;
      bottom: auto;
      right: auto;
      left: 18%;
      top: 16%;
      transform: rotate(5deg); }
    .banner__notification {
      font-weight: 400;
      font-size: 0.75rem;
      border-radius: 8px;
      padding: 6px 10px; }
    .banner__title {
      margin: 16px 0 280px; }
    .banner__text {
      margin-bottom: 16px; }
    .banner__list {
      margin: 24px 0 0;
      order: 2; }
    .banner__item {
      margin-bottom: 16px; }
      .banner__item:before {
        margin-right: 16px; } }

.btn {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 126%;
  text-align: center;
  text-transform: uppercase;
  color: #E5E5E5;
  background: linear-gradient(90deg, #CC00A9 0%, #4F0090 100%);
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  padding: 21px 0;
  text-decoration: none;
  display: block;
  width: 100%;
  cursor: pointer;
  transition: all 1s ease-in-out; }
  .btn:hover {
    background: #460080;
    transition: all 1s ease-in-out; }

@media (max-width: 740px) {
  .btn {
    padding: 16px 0; } }

.story {
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative; }
  .story--start {
    background-image: url("../img/bubbleBg1.png");
    padding-top: 106px;
    margin-top: -120px; }
  .story--more {
    background-image: url("../img/bubbleBg2.png");
    background-size: cover; }
  .story--final {
    background-image: url("../img/bubbleBg3.png");
    background-size: cover; }
  .story__bubble {
    position: absolute; }
  .story__inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .story__content {
    width: 100%;
    max-width: 900px;
    margin-left: 112px;
    position: relative; }
  .story__title {
    font-weight: bold;
    font-size: 3rem;
    line-height: 125%;
    color: #FFFFFF;
    margin-bottom: 13px; }
  .story__text {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 133%;
    color: #FFFFFF;
    margin-bottom: 30px;
    position: relative; }
    .story__text--bold {
      font-weight: 700; }
    .story__text--more {
      max-width: 416px; }
    .story__text--left:before {
      background-image: url("../img/left.png");
      top: 10px;
      left: -130px;
      content: '';
      background-repeat: no-repeat;
      width: 114px;
      height: 88px;
      display: inline-block;
      position: absolute; }
    .story__text--right:after {
      background-image: url("../img/right.png");
      bottom: 10px;
      right: -114px;
      content: '';
      background-repeat: no-repeat;
      width: 114px;
      height: 88px;
      display: inline-block;
      position: absolute; }
  .story__img {
    position: relative;
    z-index: 10;
    align-self: flex-end;
    width: 100%;
    max-width: 528px; }

@media (min-width: 1920px) {
  .story {
    background-size: cover; }
    .story--start {
      padding-top: 0;
      margin-top: 0;
      background-color: #B800BA;
      background-image: url("../img/bubbleBg.png"); } }

@media (max-width: 1170px) {
  .story {
    padding-top: 40px; }
    .story--start {
      background-color: #B800BA;
      background-image: url("../img/bubbleBg.png");
      padding-top: 0;
      margin-top: 0;
      background-size: cover; }
    .story__inner {
      flex-direction: column; }
    .story__content {
      margin-left: 0;
      padding: 0 88px; }
    .story__text {
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 133%;
      color: #FFFFFF;
      margin-bottom: 30px;
      position: relative; }
      .story__text--bold {
        font-weight: 700; }
      .story__text--more {
        max-width: 416px; }
      .story__text--left:before {
        background-image: url("../img/left.png");
        top: 10px;
        left: -114px;
        content: '';
        background-repeat: no-repeat;
        width: 114px;
        height: 88px;
        display: inline-block;
        position: absolute; }
      .story__text--right:after {
        background-image: url("../img/right.png");
        bottom: 10px;
        right: -114px;
        content: '';
        background-repeat: no-repeat;
        width: 114px;
        height: 88px;
        display: inline-block;
        position: absolute; } }

@media (max-width: 740px) {
  .story {
    padding-top: 40px; }
    .story--start {
      background-image: url("../img/bubbleBg-mb.png");
      background-size: cover; }
    .story__title {
      font-weight: 400;
      font-size: 1.5rem;
      margin-bottom: 24px; }
    .story__content {
      padding: 0 24px; }
    .story__text {
      font-size: 1rem;
      margin-bottom: 24px; }
      .story__text--bold {
        font-weight: 700; }
      .story__text--more {
        max-width: 240px; }
      .story__text--left:before {
        top: 5px;
        left: -40px;
        width: 40px;
        height: 31px;
        background-size: contain; }
      .story__text--right:after {
        background-size: contain;
        bottom: 5px;
        right: -40px;
        width: 40px;
        height: 31px; }
      .story__text--final:after {
        display: none; }
    .story__img--more {
      margin-top: -100px;
      margin-right: -54px; }
    .story__img--final {
      margin-top: -100px;
      align-self: flex-end;
      margin-right: -100px; } }

.about {
  padding: 280px 0 200px;
  background-image: url("../img/bgAbout.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden; }
  .about__item {
    background: #FFFFFF;
    box-shadow: 0 4px 63px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    padding: 88px 80px 88px 56px;
    margin-bottom: 272px; }
  .about__item-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 125%;
    color: #000000;
    margin-bottom: 40px; }
  .about__item-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .about__item-text {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 133%;
    color: #000000;
    max-width: 584px; }
    .about__item-text--sm {
      max-width: 448px; }
  .about__title {
    font-weight: 600;
    font-size: 4rem;
    line-height: 125%;
    text-align: center;
    color: #000000;
    margin-bottom: 80px;
    position: relative;
    z-index: 10; }
  .about__content {
    margin: 0 auto;
    width: 100%;
    max-width: 665px;
    position: relative; }
  .about__head {
    position: absolute;
    width: 100%; }
    .about__head--4 {
      bottom: -20px;
      left: -3%;
      max-width: 166px; }
    .about__head--5 {
      top: 5%;
      right: 0;
      max-width: 159px; }
  .about__article {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 133%;
    display: flex;
    align-items: flex-start;
    color: #000000;
    margin-bottom: 40px; }
    .about__article:before {
      content: '';
      background-image: url("../img/check.png");
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      display: inline-block;
      flex-shrink: 0;
      margin-right: 8px;
      margin-top: 2px; }
  .about__text {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 133%;
    text-align: center;
    color: #000000;
    margin-bottom: 40px;
    max-width: 640px; }
  .about__btn {
    max-width: 352px;
    margin: 0 auto; }

@media (max-width: 1230px) {
  .about__item-content {
    flex-direction: column; }
  .about__item-text {
    width: 100%;
    max-width: 100%; }
    .about__item-text--sm {
      max-width: 100%; } }

@media (max-width: 740px) {
  .about {
    padding: 80px 0 40px; }
    .about__item {
      padding: 32px 16px;
      margin-bottom: 56px; }
    .about__item-title {
      font-size: 1.375rem;
      margin-bottom: 24px;
      text-align: center; }
    .about__item-text {
      font-size: 1rem; }
    .about__title {
      font-size: 1.5rem;
      line-height: 125%;
      margin-bottom: 40px; }
    .about__head--4 {
      bottom: 25%;
      left: auto;
      right: -10px;
      max-width: 110px; }
    .about__head--5 {
      top: -11%;
      right: auto;
      left: -10px;
      max-width: 44px;
      transform: rotate(-45deg); }
    .about__article {
      font-size: 1rem;
      margin-bottom: 24px; }
      .about__article:before {
        width: 16px;
        height: 16px;
        background-size: contain; }
    .about__text {
      font-size: 1rem;
      margin-bottom: 24px;
      margin-top: 72px; } }

.programm {
  background-image: url("../img/bgAbout.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 0 120px; }
  .programm__title {
    font-weight: bold;
    font-size: 4rem;
    line-height: 112%;
    text-align: center;
    color: #000000; }
  .programm__subtitle {
    font-weight: normal;
    font-size: 2rem;
    line-height: 125%;
    text-align: center;
    color: #000000;
    max-width: 639px;
    margin: 80px auto 40px; }
  .programm__content {
    background: #FFFFFF;
    box-shadow: 0 4px 63px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 40px 40px 24px;
    max-width: 640px;
    margin: 0 auto 24px;
    position: relative; }
  .programm__head {
    position: absolute;
    top: -45px;
    right: -50px;
    width: 100%;
    max-width: 256px; }
  .programm__item {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 133%;
    color: #000000;
    margin-bottom: 16px; }
  .programm__text {
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    text-align: center;
    color: #000000;
    max-width: 640px;
    margin: 0 auto; }

@media (max-width: 740px) {
  .programm {
    padding: 80px 0 40px;
    overflow: hidden; }
    .programm__title {
      font-size: 1.5rem; }
    .programm__subtitle {
      font-size: 1rem;
      margin: 24px auto 40px; }
    .programm__content {
      padding: 24px 16px;
      margin: 0 auto 0; }
    .programm__head {
      top: 30%;
      right: -39px;
      max-width: 100px; }
    .programm__item {
      font-size: 1rem; }
    .programm__text {
      display: none; } }

.independence {
  background-color: #6500E5;
  padding: 160px 0;
  overflow: hidden; }
  .independence__inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .independence__item {
    width: 100%;
    max-width: 800px; }
  .independence__title {
    font-weight: bold;
    font-size: 4rem;
    line-height: 112%;
    color: #FFFFFF;
    margin-bottom: 40px; }
  .independence__text {
    font-weight: normal;
    font-size: 2rem;
    line-height: 125%;
    color: #FFFFFF; }
  .independence__head {
    display: none; }

@media (max-width: 980px) {
  .independence__inner {
    flex-direction: column;
    align-items: center;
    position: relative; }
  .independence__text {
    margin-bottom: 40px; }
  .independence__head {
    display: block;
    position: absolute;
    top: -160px;
    right: 0; } }

@media (max-width: 740px) {
  .independence {
    padding: 80px 0 40px; }
    .independence__title {
      font-size: 1.5rem;
      margin-bottom: 24px;
      position: relative;
      z-index: 10; }
    .independence__text {
      font-size: 1rem; }
    .independence__head {
      display: block;
      position: absolute;
      top: -80px;
      right: -40px;
      width: 100%;
      max-width: 150px;
      z-index: 2; } }

.form {
  width: 100%;
  max-width: 416px;
  padding: 24px 32px 32px;
  background: #FFFFFF;
  border-radius: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10; }
  .form--goal {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 784px; }
  .form__info {
    width: 100%;
    max-width: 352px; }
  .form__action--goal {
    max-width: 304px; }
  .form__title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 133%;
    text-align: center;
    color: #000000; }
  .form__error {
    color: red;
    font-size: 0.625rem;
    margin-bottom: 5px; }
  .form__discount {
    background-color: #B800BA;
    border-radius: 16px;
    padding: 16px 16px 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .form__discount--goal {
      background-color: #676767; }
  .form__discount-text {
    font-weight: normal;
    font-size: 1rem;
    line-height: 120%;
    color: #FFFFFF;
    max-width: 150px; }
  .form__text {
    font-weight: normal;
    font-size: 1rem;
    line-height: 120%;
    text-align: center;
    color: #000000;
    margin: 24px 0; }
    .form__text--goal {
      margin-top: 6px; }
  .form__input {
    background: #E5E5E5;
    border-radius: 16px;
    padding: 16px 0;
    display: block;
    width: 100%;
    border: none;
    text-indent: 32px;
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 1rem;
    line-height: 157%;
    color: #000000; }
    .form__input::placeholder {
      color: #676767;
      font-size: 1rem; }
  .form__btn {
    border: none; }
    .form__btn:disabled {
      background-color: grey;
      cursor: not-allowed; }
  .form__head {
    position: absolute;
    width: 100%;
    max-width: 200px;
    top: -125px;
    right: -45px; }

@media (max-width: 980px) {
  .form__head {
    display: none; } }

@media (max-width: 740px) {
  .form {
    padding: 24px 16px;
    border-radius: 16px; }
    .form--goal {
      flex-direction: column;
      max-width: 480px;
      align-items: center;
      margin: 0 auto; }
    .form__title {
      font-size: 1rem; }
    .form__discount {
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      flex-direction: column; }
      .form__discount--goal {
        background-color: transparent; }
    .form__discount-text {
      font-size: 0.75rem;
      color: #000000;
      text-align: center;
      max-width: 113px;
      margin-bottom: 8px; }
    .form__text {
      margin: 16px 0 24px; }
      .form__text--goal {
        margin-top: 6px; }
    .form__btn {
      margin-top: 16px; } }

.timer {
  display: flex;
  align-items: stretch;
  justify-content: space-between; }
  .timer__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .timer__num {
    font-weight: bold;
    font-size: 2rem;
    line-height: 112%;
    color: #FFFFFF;
    width: 72px;
    text-align: center;
    position: relative; }
    .timer__num--dots:before {
      content: ':';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -5%; }
  .timer__text {
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 133%;
    color: #FFFFFF; }

@media (max-width: 740px) {
  .timer {
    background-color: #B800BA;
    border-radius: 16px;
    padding: 8px; } }

.price {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 33px 0 13px;
  gap: 20px; }
  .price__new {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 100%;
    color: #000000; }
  .price__text {
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    color: #000000; }
  .price__old {
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    color: #000000; }

@media (max-width: 740px) {
  .price {
    margin: 32px 0 22px; }
    .price__new {
      font-size: 2.1875rem; }
    .price__text {
      font-size: 0.875rem; }
    .price__old {
      font-size: 0.875rem; } }

.skills {
  padding: 240px 0 200px;
  overflow: hidden; }
  .skills__title {
    font-weight: bold;
    font-size: 4rem;
    line-height: 125%;
    text-align: center;
    color: #000000;
    margin: 0 auto 136px;
    max-width: 864px;
    position: relative;
    z-index: 10; }
  .skills__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 32px; }
  .skills__item {
    flex: 1 1 calc((100% / 3) - 64px);
    background-color: #FFFFFF;
    box-shadow: 0 4px 63px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 64px 30px 48px;
    text-align: center;
    min-width: 280px;
    color: #000000;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 133%; }
    .skills__item--blue {
      background-color: #6500E5;
      color: #FFFFFF; }
  .skills__head {
    position: absolute;
    width: 100%; }
    .skills__head--1 {
      top: 477px;
      left: 342px;
      max-width: 200px; }
    .skills__head--2 {
      bottom: 230px;
      right: 330px;
      max-width: 216px; }

@media (max-width: 1300px) {
  .skills__head--1 {
    top: 130px;
    left: 0; }
  .skills__head--2 {
    bottom: auto;
    top: 130px;
    right: 0; } }

@media (max-width: 740px) {
  .skills {
    padding: 100px 0 40px; }
    .skills__title {
      font-size: 1.5rem;
      margin: 0 auto 40px; }
    .skills__item {
      padding: 16px 8px;
      font-size: 1rem; }
      .skills__item--blue {
        background-color: #6500E5;
        color: #FFFFFF; }
    .skills__head--1 {
      top: -100px;
      left: -20px;
      max-width: 130px;
      filter: drop-shadow(0px 4px 35px rgba(197, 0, 229, 0.25)); }
    .skills__head--2 {
      max-width: 110px;
      top: -90px;
      right: -15px;
      filter: drop-shadow(0px 4px 35px rgba(197, 0, 229, 0.25)); } }

.reviews {
  background-image: url("../img/bgAbout.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 144px 0 280px; }
  .reviews__title {
    font-weight: bold;
    font-size: 4rem;
    line-height: 112%;
    text-align: center;
    color: #000000;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .reviews__title:after {
      content: '';
      background-image: url("../img/head10.png");
      background-repeat: no-repeat;
      width: 216px;
      height: 242px;
      display: inline-block;
      transform: translate(-10px, -35px); }
  .reviews__item-holder {
    height: auto;
    position: relative;
    display: flex !important;
    flex-direction: column; }
    .reviews__item-holder:after {
      content: '';
      display: block;
      height: 46px;
      width: 43px;
      background: #FFFFFF;
      border: inherit;
      align-self: flex-end;
      transform: translate(-84px, -22px) rotate(-45deg); }
  .reviews__item {
    background: #FFFFFF;
    border-radius: 24px;
    padding: 32px 32px 24px;
    width: 100%;
    max-width: 464px;
    height: 100%;
    margin-right: 32px; }
  .reviews__item-images {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px; }
  .reviews__item-photo {
    width: 100%;
    max-width: 72px; }
  .reviews__item-stars {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 152px; }
  .reviews__item-star {
    width: 100%;
    max-width: 24px; }
  .reviews__item-name {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 133%;
    color: #000000;
    margin: 16px 0 24px; }
  .reviews__item-text {
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    color: #000000; }
    .reviews__item-text--bold {
      font-weight: 700; }
  .reviews__icon {
    display: block;
    margin: 0 auto;
    animation: slide 4s linear;
    animation-direction: alternate;
    animation-iteration-count: infinite; }

.slick-track {
  display: flex;
  align-items: stretch; }

@keyframes slide {
  0% {
    opacity: 1; }
  10% {
    opacity: 1;
    transform: translate(-20px, 10px); }
  20% {
    opacity: 1;
    transform: translate(-40px, 10px); }
  30%, 100% {
    opacity: 0; } }

@media (max-width: 740px) {
  .reviews {
    overflow: hidden;
    padding: 56px 16px 40px; }
    .reviews__title {
      font-size: 1.5rem;
      margin-bottom: 24px;
      position: relative; }
      .reviews__title:after {
        position: absolute;
        right: -16px;
        background-size: contain;
        width: 99px;
        height: 99px;
        margin-bottom: -20px;
        filter: drop-shadow(0px 4px 35px rgba(197, 0, 229, 0.25)); }
    .reviews__slider {
      width: 100%;
      max-width: 700px;
      margin: 0 auto; }
    .reviews__item-holder:after {
      content: '';
      height: 46px;
      width: 43px;
      background: #FFFFFF;
      border: inherit;
      align-self: flex-end;
      transform: translate(-520%, -22px) rotate(-45deg);
      display: none; }
    .reviews__item {
      border-radius: 16px;
      padding: 16px 8px 40px 16px;
      width: 100%;
      max-width: 312px;
      margin: 0 auto; } }

.goal {
  background-color: #6500E5;
  padding: 200px 0 127px; }
  .goal__tablet {
    position: absolute;
    bottom: -70px;
    right: 0;
    z-index: 1;
    width: 100%;
    max-width: 816px; }
  .goal__title {
    font-weight: bold;
    font-size: 4rem;
    line-height: 112%;
    color: #FFFFFF; }
  .goal__inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    z-index: 10; }
  .goal__list {
    list-style: none;
    width: 100%;
    max-width: 591px;
    margin: 80px 0 48px; }
  .goal__article {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 133%;
    color: #FFFFFF;
    margin-bottom: 32px; }
  .goal__item {
    width: 100%;
    max-width: 304px; }
  .goal__img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 120px; }
  .goal__text {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 133%;
    text-align: center;
    color: #FFFFFF; }
  .goal__subtext {
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    color: #FFFFFF;
    margin-top: 16px;
    width: 100%;
    max-width: 784px;
    text-align: center;
    position: relative;
    z-index: 10; }

@media (max-width: 1160px) {
  .goal__inner {
    margin-bottom: 176px; } }

@media (max-width: 740px) {
  .goal {
    padding: 40px 0; }
    .goal__tablet {
      bottom: 45%;
      max-width: 334px;
      right: 0;
      z-index: 1; }
    .goal__title {
      font-size: 1.5rem; }
    .goal__inner {
      flex-direction: column;
      align-items: center; }
    .goal__list {
      margin: 40px 0 16px; }
    .goal__article {
      font-size: 1rem;
      margin-bottom: 16px; }
    .goal__img {
      max-width: 80px; }
    .goal__text {
      font-size: 1rem; }
    .goal__subtext {
      font-size: 0.875rem;
      margin-top: 24px; } }

.questions {
  padding: 280px 0 225px;
  overflow: hidden; }
  .questions__title {
    font-weight: bold;
    font-size: 4rem;
    line-height: 112%;
    text-align: center;
    color: #000000;
    margin-bottom: 61px; }
  .questions__item {
    background: #FFFFFF;
    box-shadow: 0 4px 63px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    padding: 24px 40px;
    margin: 0 auto 20px;
    position: relative;
    width: 100%;
    max-width: 1088px; }
    .questions__item:before {
      content: '';
      width: 216px;
      height: 216px;
      display: inline-block;
      position: absolute;
      background-repeat: no-repeat;
      bottom: 0;
      left: -150px;
      background-size: contain; }
    .questions__item:after {
      content: '';
      width: 216px;
      height: 216px;
      display: inline-block;
      position: absolute;
      background-repeat: no-repeat;
      bottom: 0;
      right: -150px;
      background-size: contain; }
    .questions__item--1:before {
      background-image: url("../img/head12.png"); }
    .questions__item--2:after {
      background-image: url("../img/head13.png"); }
    .questions__item--3:before {
      background-image: url("../img/head14.png"); }
    .questions__item--4:after {
      background-image: url("../img/head15.png"); }
  .questions__item-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 125%;
    color: #000000;
    position: relative;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer; }
    .questions__item-title:after {
      content: '';
      background-image: url("../img/arrow.png");
      display: inline-block;
      background-repeat: no-repeat;
      background-size: contain;
      width: 16px;
      height: 16px;
      transform: scaleY(-1);
      cursor: pointer;
      transition: all .4s linear;
      flex-shrink: 0; }
    .questions__item-title.active:after {
      transform: scaleY(1); }
  .questions__item-text {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 133%;
    color: #000000;
    margin-top: 16px;
    max-width: 1008px;
    display: none; }

@media (max-width: 1270px) {
  .questions__item {
    background: #FFFFFF;
    box-shadow: 0 4px 63px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    padding: 24px 40px;
    margin: 0 auto 20px;
    position: relative;
    width: 100%;
    max-width: 1088px; }
    .questions__item--1:before {
      left: auto;
      right: -40px;
      bottom: auto;
      top: -95px;
      width: 104px;
      height: 104px; }
    .questions__item--2:after {
      width: 48px;
      right: -10px;
      height: 48px;
      bottom: 0; }
    .questions__item--3:before {
      width: 60px;
      height: 60px;
      left: -30px;
      bottom: auto;
      top: -60px; }
    .questions__item--4:after {
      width: 56px;
      height: 56px;
      bottom: 0;
      right: -10px; } }

@media (max-width: 740px) {
  .questions {
    padding: 40px 0 16px; }
    .questions__title {
      font-size: 1.5rem;
      margin-bottom: 40px; }
    .questions__item {
      border-radius: 8px;
      padding: 16px;
      margin: 0 auto 16px; }
    .questions__item-title {
      font-size: 1.125rem; }
    .questions__item-text {
      font-size: 1rem; } }

.footer {
  background: linear-gradient(90deg, #CC00A9 0%, #4F0090 100%);
  padding: 40px 0 21px; }
  .footer__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .footer__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 270px; }
  .footer__info {
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 114%;
    color: #FFFFFF;
    margin-bottom: 8px; }
    .footer__info--link {
      text-decoration: none; }
  .footer__pay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 14px;
    width: 100%;
    max-width: 280px; }

@media (max-width: 800px) {
  .footer__inner {
    flex-wrap: wrap; }
  .footer__item--middle {
    order: 2; } }
