.goal {
  background-color: $bgInd;
  padding: 200px 0 127px;
  &__tablet {
    position: absolute;
    bottom: -70px;
    right: 0;
    z-index: 1;
    width: 100%;
    max-width: 816px;
  }
  &__title {
    font-weight: bold;
    font-size: rem(64);
    line-height: 112%;
    color: $mainTxtLight;
  }
  &__inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    z-index: 10;
  }
  &__list {
    list-style: none;
    width: 100%;
    max-width: 591px;
    margin: 80px 0 48px;
  }
  &__article {
    font-weight: normal;
    font-size: rem(24);
    line-height: 133%;
    color: $mainTxtLight;
    margin-bottom: 32px;
  }
  &__item {
    width: 100%;
    max-width: 304px;
  }
  &__img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 120px;
  }
  &__text {
    font-weight: 600;
    font-size: rem(24);
    line-height: 133%;
    text-align: center;
    color: $mainTxtLight;
  }
  &__subtext {
    font-weight: normal;
    font-size: rem(16);
    line-height: 150%;
    color: $mainTxtLight;
    margin-top: 16px;
    width: 100%;
    max-width: 784px;text-align: center;
    position: relative;
    z-index: 10;
  }
}
@media (max-width: 1160px) {
  .goal {
    &__inner {
      margin-bottom: 176px;
    }
  }
}

@media (max-width: 740px) {
  .goal {
    padding: 40px 0;
    &__tablet {
      bottom: 45%;
      max-width: 334px;
      right: 0;
      z-index: 1;
    }
    &__title {
      font-size: rem(24);
    }
    &__inner {
      flex-direction: column;
      align-items: center;
    }
    &__list {
      margin: 40px 0 16px;
    }
    &__article {
      font-size: rem(16);
      margin-bottom: 16px;
    }
    &__img {
      max-width: 80px;
    }
    &__text {
      font-size: rem(16);
    }
    &__subtext {
      font-size: rem(14);
      margin-top: 24px;
    }
  }
}