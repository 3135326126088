.about {
  padding: 280px 0 200px;
  background-image: url("../img/bgAbout.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  &__item {
    background: $mainTxtLight;
    box-shadow: 0 4px 63px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    padding: 88px 80px 88px 56px;
    margin-bottom: 272px;
  }
  &__item-title {
    font-weight: 600;
    font-size: rem(32);
    line-height: 125%;
    color: $mainTxtDark;
    margin-bottom: 40px;
  }
  &__item-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__item-text {
    font-weight: normal;
    font-size: rem(24);
    line-height: 133%;
    color: $mainTxtDark;
    max-width: 584px;
    &--sm {
      max-width: 448px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: rem(64);
    line-height: 125%;
    text-align: center;
    color: $mainTxtDark;
    margin-bottom: 80px;
    position: relative;
    z-index: 10;
  }
  &__content {
    margin: 0 auto;
    width: 100%;
    max-width: 665px;
    position: relative;
  }
  &__head {
    position: absolute;
    width: 100%;
    &--4 {
      bottom: -20px;
      left: -3%;
      max-width: 166px;
    }
    &--5 {
      top: 5%;
      right: 0;
      max-width: 159px;
    }
  }
  &__article {
    font-weight: normal;
    font-size: rem(24);
    line-height: 133%;
    display: flex;
    align-items: flex-start;
    color: $mainTxtDark;
    margin-bottom: 40px;
    &:before {
      content: '';
      background-image: url("../img/check.png");
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      display: inline-block;
      flex-shrink: 0;
      margin-right: 8px;
      margin-top: 2px;
    }
  }
  &__text {
    font-weight: 600;
    font-size: rem(24);
    line-height: 133%;
    text-align: center;
    color: $mainTxtDark;
    margin-bottom: 40px;
    max-width: 640px;
  }
  &__btn {
    max-width: 352px;
    margin: 0 auto;
  }

}

@media (max-width: 1230px) {
  .about {
    &__item-content {
      flex-direction: column;
    }
    &__item-text {
      width: 100%;
      max-width: 100%;
      &--sm {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 740px) {
  .about {
    padding: 80px 0 40px;
    &__item {
      padding: 32px 16px;
      margin-bottom: 56px;
    }
    &__item-title {
      font-size: rem(22);
      margin-bottom: 24px;
      text-align: center;
    }
    &__item-text {
      font-size: rem(16);
    }
    &__title {
      font-size: rem(24);
      line-height: 125%;
      margin-bottom: 40px;
    }
    &__head {
      &--4 {
        bottom: 25%;
        left: auto;
        right: -10px;
        max-width: 110px;
      }
      &--5 {
        top: -11%;
        right: auto;
        left: -10px;
        max-width: 44px;
        transform: rotate(-45deg);
      }
    }
    &__article {
      font-size: rem(16);
      margin-bottom: 24px;
      &:before {
        width: 16px;
        height: 16px;
        background-size: contain;
      }
    }
    &__text {
      font-size: rem(16);
      margin-bottom: 24px;
      margin-top: 72px;
    }
  }
}