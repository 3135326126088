.programm {
  background-image: url("../img/bgAbout.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 0 120px;
  &__title {
    font-weight: bold;
    font-size: rem(64);
    line-height: 112%;
    text-align: center;
    color: $mainTxtDark;
  }
  &__subtitle {
    font-weight: normal;
    font-size: rem(32);
    line-height: 125%;
    text-align: center;
    color: $mainTxtDark;
    max-width: 639px;
    margin: 80px auto 40px;
  }
  &__content {
    background: $mainTxtLight;
    box-shadow: 0 4px 63px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 40px 40px 24px;
    max-width: 640px;
    margin: 0 auto 24px;
    position: relative;
  }
  &__head {
    position: absolute;
    top: -45px;
    right: -50px;
    width: 100%;
    max-width: 256px;
  }
  &__item {
    font-weight: normal;
    font-size: rem(24);
    line-height: 133%;
    color: $mainTxtDark;
    margin-bottom: 16px;
  }
  &__text {
    font-weight: normal;
    font-size: rem(16);
    line-height: 150%;
    text-align: center;
    color: $mainTxtDark;
    max-width: 640px;
    margin: 0 auto;
  }
}
@media (max-width: 740px) {
  .programm {
    padding: 80px 0 40px;
    overflow: hidden;
    &__title {
      font-size: rem(24);
    }
    &__subtitle {
      font-size: rem(16);
      margin: 24px auto 40px;
    }
    &__content {
      padding: 24px 16px;
      margin: 0 auto 0;
    }
    &__head {
      top: 30%;
      right: -39px;
      max-width: 100px;
    }
    &__item {
      font-size: rem(16);
    }
    &__text {
      display: none;
    }
  }
}