.story {
  //background-color: $bgStory;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;

  &--start {
    background-image: url("../img/bubbleBg1.png");
    padding-top: 106px;
    margin-top: -120px;
  }
  &--more {
    background-image: url("../img/bubbleBg2.png");
    background-size: cover;
  }
  &--final {
    background-image: url("../img/bubbleBg3.png");
    background-size: cover;
  }
  &__bubble{
    position: absolute;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__content {
    width: 100%;
    max-width: 900px;
    margin-left: 112px;
    position: relative;
  }
  &__title {
    font-weight: bold;
    font-size: rem(48);
    line-height: 125%;
    color: $mainTxtLight;
    margin-bottom: 13px;
  }
  &__text {
    font-weight: normal;
    font-size: rem(24);
    line-height: 133%;
    color: $mainTxtLight;
    margin-bottom: 30px;
    position: relative;
    &--bold {
      font-weight: 700;
    }
    &--more {
      max-width: 416px;
    }
    &--left:before {
      background-image: url("../img/left.png");
      top: 10px;
      left: -130px;
      content: '';
      background-repeat: no-repeat;
      width: 114px;
      height: 88px;
      display: inline-block;
      position: absolute;
    }
    &--right:after {
      background-image: url("../img/right.png");
      bottom: 10px;
      right: -114px;
      content: '';
      background-repeat: no-repeat;
      width: 114px;
      height: 88px;
      display: inline-block;
      position: absolute;
    }
  }
  &__img {
    position: relative;
    z-index: 10;
    align-self: flex-end;
    width: 100%;
    max-width: 528px;
  }
}

@media (min-width: 1920px) {
  .story {
    background-size: cover;
    &--start {
      padding-top: 0;
      margin-top: 0;
      background-color: $bgStory;
      background-image: url("../img/bubbleBg.png");
    }

  }
}
@media (max-width: 1170px) {
  .story {
    padding-top: 40px;
    &--start {
      background-color: $bgStory;
      background-image: url("../img/bubbleBg.png");
      padding-top: 0;
      margin-top: 0;
      background-size: cover;
    }
    &__inner {
      flex-direction: column;
    }
    &__content {
      margin-left: 0;
      padding: 0 88px;
    }
    &__text {
      font-weight: normal;
      font-size: rem(24);
      line-height: 133%;
      color: $mainTxtLight;
      margin-bottom: 30px;
      position: relative;
      &--bold {
        font-weight: 700;
      }
      &--more {
        max-width: 416px;
      }
      &--left:before {
        background-image: url("../img/left.png");
        top: 10px;
        left: -114px;
        content: '';
        background-repeat: no-repeat;
        width: 114px;
        height: 88px;
        display: inline-block;
        position: absolute;
      }
      &--right:after {
        background-image: url("../img/right.png");
        bottom: 10px;
        right: -114px;
        content: '';
        background-repeat: no-repeat;
        width: 114px;
        height: 88px;
        display: inline-block;
        position: absolute;
      }
    }
  }
}
@media (max-width: 740px) {
  .story {
    padding-top: 40px;
    &--start {
      background-image: url("../img/bubbleBg-mb.png");
      background-size: cover;
    }
    &__title {
      font-weight: 400;
      font-size: rem(24);
      margin-bottom: 24px;
    }
    &__content {
      padding: 0 24px;
    }
    &__text {
      font-size: rem(16);
      margin-bottom: 24px;
      &--bold {
        font-weight: 700;
      }
      &--more {
        max-width: 240px;
      }
      &--left:before {
        top: 5px;
        left: -40px;
        width: 40px;
        height: 31px;
        background-size: contain;
      }
      &--right:after {
        background-size: contain;
        bottom: 5px;
        right: -40px;
        width: 40px;
        height: 31px;
      }
      &--final:after {
        display: none;
      }
    }
    &__img {
      &--more {
        margin-top: -100px;
        margin-right: -54px;
      }
      &--final {
        margin-top: -100px;
        align-self: flex-end;
        margin-right: -100px;
      }
    }
  }
}