.timer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__num {
    font-weight: bold;
    font-size: rem(32);
    line-height: 112%;
    color: $mainTxtLight;
    width: 72px;
    text-align: center;
    position: relative;
    &--dots {
      &:before {
        content: ':';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -5%;
      }
    }
  }
  &__text {
    font-weight: normal;
    font-size: rem(12);
    line-height: 133%;
    color: $mainTxtLight;
  }
}
@media (max-width: 740px) {
  .timer {
    background-color: $bgDiscount;
    border-radius: 16px;
    padding: 8px;
  }
}