.independence {
  background-color: $bgInd;
  padding: 160px 0;
  overflow: hidden;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item {
    width: 100%;
    max-width: 800px;
  }
  &__title {
    font-weight: bold;
    font-size: rem(64);
    line-height: 112%;
    color: $mainTxtLight;
    margin-bottom: 40px;
  }
  &__text {
    font-weight: normal;
    font-size: rem(32);
    line-height: 125%;
    color: $mainTxtLight;
  }
  &__head {
    display: none;
  }
}
@media (max-width: 980px) {
  .independence {
    &__inner {
      flex-direction: column;
      align-items: center;
      position: relative;
    }
    &__text {
      margin-bottom: 40px;
    }
    &__head {
      display: block;
      position: absolute;
      top: -160px;
      right: 0;
    }
  }
}
@media (max-width: 740px) {
  .independence {
    padding: 80px 0 40px;
    &__title {
      font-size: rem(24);
      margin-bottom: 24px;
      position: relative;
      z-index: 10;
    }
    &__text {
      font-size: rem(16);
    }
    &__head {
      display: block;
      position: absolute;
      top: -80px;
      right: -40px;
      width: 100%;
      max-width: 150px;
      z-index: 2;
    }
  }
}