.banner {
  position: relative;
  background-image: url("../img/bannerBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 192px 0 220px;
  overflow: hidden;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 10;
  }
  &__img {
    position: absolute;
    width: 100%;
    max-width: 850px;
    bottom: -400px;
    right: -130px;
  }
  &__head {
    position: absolute;
    width: 100%;
    &--1 {
      max-width: 300px;
      top: -125px;
      right: 20px;
    }
    &--2 {
      max-width: 300px;
      bottom: 80px;
      right: 32%;
    }
    &--3 {
      max-width: 216px;
      bottom: -170px;
      right: -25px;
    }
  }
  &__notification {
    font-weight: 600;
    font-size: rem(24);
    line-height: 125%;
    color: $mainTxtLight;
    background: $gradient;
    border-radius: 144px;
    display: inline-block;
    padding: 8px 18px;
  }
  &__title {
    font-weight: bold;
    //font-size: rem(72);
    font-size: calc(26px + 32 * ((100vw - 320px)/ 1120));
    line-height: 122%;
    letter-spacing: 0.01em;
    color: $mainTxtDark;
    width: 100%;
    max-width: 1256px;
    margin: 80px 0 56px;
  }
  &__text {
    font-weight: 500;
    //font-size: rem(32);
    font-size: calc(16px + 11 * ((100vw - 320px)/ 1120));
    line-height: 125%;
    color: $mainTxtDark;
    width: 100%;
    max-width: 640px;
  }
  &__list {
    list-style: none;
    margin: 80px 0 29px;
  }
  &__item {
    font-weight: 500;
    //font-size: rem(24);
    font-size: calc(16px + 6 * ((100vw - 320px)/ 1120));
    line-height: 125%;
    color: $mainTxtDark;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &:before {
      content: '';
      background-image: url("../img/check.png");
      width: 24px;
      height: 24px;
      display: inline-block;
      flex-shrink: 0;
      margin-right: 29px;
    }
  }
  &__btn {
    max-width: 352px;
  }
}


@media (max-width: 1115px) {
  .banner {
    &__img {
      //max-width: 517px;
      max-width: calc(100vw - 55vw);
      bottom: -220px;
      right: 0;
      z-index: 2;
    }
    &__head {
      &--1 {
        max-width: 130px;
      }
      &--2 {
        max-width: 130px;
      }
      &--3 {
        max-width: 130px;
      }
    }
  }
}

@media (max-width: 740px) {
  .banner {
    padding: 40px 0 48px;
    &__head {
      &--1 {
        max-width: 70px;
        top: auto;
        bottom: 17px;
        right: 0;
      }
      &--2 {
        max-width: 130px;
        bottom: auto;
        top: 19%;
        right: auto;
        left: 0;
        transform: rotate(-15deg);
      }
      &--3 {
        max-width: 130px;
        bottom: 42%;
        right: auto;
        left: -20px;
        transform: rotate(10deg);
      }
    }
    &__img {
      max-width: 290px;
      bottom: auto;
      right: auto;
      left: 18%;
      top: 16%;
      transform: rotate(5deg);
    }
    &__notification {
      font-weight: 400;
      font-size: rem(12);
      border-radius: 8px;
      padding: 6px 10px;
    }
    &__title {
      margin: 16px 0 280px;
    }
    &__text {
     margin-bottom: 16px;
    }
    &__list {
      margin: 24px 0 0;
      order: 2;
    }
    &__item {
      margin-bottom: 16px;
      &:before {
        margin-right: 16px;
      }
    }
  }
}