.skills {
  padding: 240px 0 200px;
  overflow: hidden;
  &__title {
    font-weight: bold;
    font-size: rem(64);
    line-height: 125%;
    text-align: center;
    color: $mainTxtDark;
    margin: 0 auto 136px;
    max-width: 864px;
    position: relative;
    z-index: 10;
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 32px;
  }
  &__item {
    flex: 1 1 calc((100% / 3) - 64px);
    background-color: $mainTxtLight;
    box-shadow: 0 4px 63px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 64px 30px 48px;
    text-align: center;
    min-width: 280px;
    color: $mainTxtDark;
    font-weight: normal;
    font-size: rem(24);
    line-height: 133%;
    &--blue {
      background-color: $bgInd;
      color: $mainTxtLight;
    }
  }

  &__head {
    position: absolute;
    width: 100%;
    &--1 {
      top: 477px;
      left: 342px;
      max-width: 200px;
    }
    &--2 {
      bottom: 230px;
      right: 330px;
      max-width: 216px;
    }
  }
}
@media (max-width: 1300px) {
  .skills {
    &__head {
      &--1 {
        top: 130px;
        left: 0;
      }
      &--2 {
        bottom: auto;
        top: 130px;
        right: 0;
      }
    }
  }
}
@media (max-width: 740px)  {
  .skills {
    padding: 100px 0 40px;
    &__title {
      font-size: rem(24);
      margin: 0 auto 40px;
    }
    &__item {
      padding: 16px 8px;
      font-size: rem(16);
      &--blue {
        background-color: $bgInd;
        color: $mainTxtLight;
      }
    }
    &__head {
      &--1 {
        top: -100px;
        left: -20px;
        max-width: 130px;
        filter: drop-shadow(0px 4px 35px rgba(197, 0, 229, 0.25));
      }
      &--2 {
        max-width: 110px;
        top: -90px;
        right: -15px;
        filter: drop-shadow(0px 4px 35px rgba(197, 0, 229, 0.25));
      }
    }
  }
}