.footer {
  background: linear-gradient(90deg, #CC00A9 0%, #4F0090 100%);
  padding: 40px 0 21px;
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 270px;
  }
  &__info {
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: rem(14);
    line-height: 114%;
    color: #FFFFFF;
    margin-bottom: 8px;
    &--link {
      text-decoration: none;
    }
  }
  &__pay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 14px;
    width: 100%;
    max-width: 280px;
  }
}
@media (max-width: 800px) {
  .footer {
    &__inner {
      flex-wrap: wrap;
    }
    &__item{
      &--middle {
        order: 2;
      }
    }

  }
}
